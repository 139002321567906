<template>
  <div class="page-bg-gradient relative min-h-full">
    <Headers :title="$t('head.pool')" :isback="false">
      <!-- <template #right>
        <div class="flex items-center">
          <span>{{$t('node')}}</span>
          <img
            src="~@/assets/new/down-icon.png"
            class="w-16px h-16px"
            alt=""
            srcset=""
          />
        </div>
      </template> -->
    </Headers>
    <div
      class="absolute right-0 top-31px bg-[#7fd5b299] filter blur-100px w-138px h-207px"
    ></div>
    <div class="pt-112px px-24px">
      <div
        class="p-20px bg-[#ffffff1a] rounded-24px border border-[#ffffff0f] border-solid flex justify-between items-center"
      >
        <div class="flex items-center">
          <img
            src="~@/assets/new/CAKE.png"
            class="w-48px h-48px mr-8px"
            alt=""
            srcset=""
          />
          <div class="text-15px font-Gilroy">Cake-LP-UNC</div>
        </div>
        <div class="text-right">
          <div class="text-21px italic font-extrabold">{{ balance }}</div>
          <div class="text-13px text-[#ffffffb3] mt-6px">
            {{ $t('head.balance') }}
          </div>
        </div>
      </div>
      <div class="mt-32px text-21px font-medium">{{ $t('checkDay') }}</div>
      <div class="flex mt-12px flex-wrap">
        <div
          v-for="(item, index) in rlist"
          :key="index"
          :class="{ active: active == item.rid }"
          @click="active = item.rid"
          class="text-12px text-[#ffffffb3] h-26px leading-26px px-13px border border-[#ffffff33] rounded-8px mr-8px"
        >
          {{ item.title }}{{ $t('head.day') }}-{{ item.y }}%
        </div>
      </div>
      <div class="mt-32px">
        <div class="text-21px font-bold">Cake-LP-UNC</div>
        <div
          class="border border-[#ffffff33] rounded-16px mt-16px h-44px flex items-center"
        >
          <van-field
            v-model="amount"
            class="rounded-full inp1"
            label-width="0"
            :placeholder="$t('head.inputAmount')"
          ></van-field>
        </div>
      </div>
      <div class="mt-16px flex">
        <div
          @click="onApprove"
          class="h-34px flex items-center justify-center flex-1 rounded-12px bg-gradient-to-l from-[#2cba814d] from-opacity-30 to-[#1453bf4d] to-opacity-30 border border-[#1453bf4d]"
        >
          <img
            src="~@/assets/new/06.png"
            class="w-16px h-16px mr-6px"
            alt=""
            srcset=""
          />
          <span>{{ $t('head.aa7') }}</span>
        </div>

        <div
          @click="onDeposit"
          class="ml-25px h-34px flex items-center justify-center flex-1 rounded-12px bg-gradient-to-l from-[#2cba81] to-[#1453bf]"
        >
          <img
            src="~@/assets/new/20.png"
            class="w-16px h-16px mr-6px"
            alt=""
            srcset=""
          />
          <span>{{ $t('head.stake') }}</span>
        </div>
      </div>
      <div
        class="mt-32px bg-[#ffffff1a] rounded-24px backdrop-filter backdrop-blur-20px p-20px"
      >
        <div class="flex mb-12px justify-between text-15px">
          <div class="text-[#ffffffb3] font-Gilroy">Deposit</div>
          <div>{{ Deposit }}</div>
        </div>
        <div class="flex mb-12px justify-between text-15px">
          <div class="text-[#ffffffb3] font-Gilroy">Hash</div>
          <div>{{ Hash }}</div>
        </div>
        <div class="flex justify-between text-15px">
          <div class="text-[#ffffffb3] font-Gilroy">CountTime</div>
          <div
            class="border border-[#ffffff33] rounded-full text-13px leading-22px px-12px"
          >
            <Coundown :longTime="downTime"></Coundown>
          </div>
        </div>
        <div class="mt-16px">
          <div
            :disabled="downTime>0"
            @click="onWithdraw"
            class="btn bg-gradient-to-r from-[#e2452a] from-opacity-30 to-opacity-30 to-[#f3c721] h-44px rounded-12px leading-44px text-center border border-[#e2452a4d] text-15px"
          >
            {{ $t('head.withdraw') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from '@/components/headers.vue'
import { BigNumber } from 'bignumber.js'
import mixin from '@/mixins/index'
import Coundown from '@/components/coundown'
export default {
  mixins: [mixin],
  components: {
    Headers,
    Coundown,
  },
  data() {
    return {
      balance: 0,
      cakelpunc_contract: null,
      active: 0,
      amount: null,
      newBlockNumber: 0,
      Hash: '',
      Deposit: '',
      endBlock: 0,
      rlist: [
        {
          title: '30',
          x: 2,
          rid: 0,
          amount: '',
          loading: false,
          dploading: false,
          y: 200,
        },
        {
          title: '180',
          x: 5,
          rid: 1,
          amount: '',
          loading: false,
          dploading: false,
          y: 500,
        },
        {
          title: '360',
          x: 10,
          rid: 2,
          amount: '',
          loading: false,
          dploading: false,
          y: 1000,
        },
      ],
    }
  },
  computed: {
    currentObj() {
      return this.rlist.find((e) => e.rid == this.active)
    },
    downTime() {
      return (this.endBlock - this.newBlockNumber) * 3
    },
  },
  methods: {
    // 全部赎回
    onWithdraw() {
      this.LP_contract.methods
        .withdraw(0)
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('SUCCESS')
          this.getdeposit()
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    // 授权
    onApprove() {
      if (this.amount == '' || this.amount <= 0)
        return this.$toast(this.$t('head.sl'))

      const price = (this.amount * 10 ** 18 * 100).toFixed(0)

      this.cakelpunc_contract.methods
        .approve(
          this.pool.LPPool,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('APPROVE SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    // 锁仓
    onDeposit() {
      if (this.amount == '' || this.amount <= 0)
        return this.$toast(this.$t('head.sl'))

      this.LP_contract.methods
        .deposit(
          0,
          this.global.web3.utils.toWei(this.amount + '', 'ether'),
          this.currentObj.rid
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    dev() {
      this.$toast(this.$t('head.dev'))
    },
    getdeposit() {
      this.LP_contract.methods
        .getDeposit(this.global.account, 0)
        .call()
        .then((res) => {
          this.Hash = (res.amounthr / 1e18).toFixed(6)
          this.Deposit = (res.amountlp / 1e18).toFixed(6)
          this.endBlock = res.endblock
          if (this.Hash == 0) this.Hash = 0
          if (this.Deposit == 0) this.Deposit = 0
          if (this.endBlock == 0) this.endBlock = 0
        })
    },
    getBalancee() {
      this.cakelpunc_contract.methods
        .balanceOf(this.global.account)
        .call()
        .then((res) => {
          this.balance = (res / 1e18).toFixed(3)
        })
    },
  },
  watch: {
    'global.account'() {
      this.global.web3.eth.getBlockNumber().then((res) => {
        this.newBlockNumber = res
      })

      this.LP_contract = new this.global.web3.eth.Contract(
        this.poolabi.lppool_abi,
        this.pool.LPPool
      )

      this.cakelpunc_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphpool_abi2,
        this.pool.cakelpunc
      )

      this.getdeposit()
      this.getBalancee()
      console.log(this.LP_contract)
    },
  },
}
</script>
<style lang="scss" scoped>
.active {
  // @apply bg-[#ffffff] bg-opacity-20;
  @apply bg-gradient-to-l from-[#2cba81] to-[#1453bf] border-transparent;
}
.btn[disabled]{
  @apply opacity-80 bg-gray-400 pointer-events-none;
}
</style>
